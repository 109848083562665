import { apiFetch } from "./apiFetch";

type QueryParams = {
  page?: string;
  perPage?: string;
  sortColumn?: string;
  sortDirection?: string;
  'searchColumn[]'?: string;
  'searchTerm[]'?: string;
  id_shop?: string;
  active?: string;
}

const customersRoute = '/api/customers'

export async function fetchCustomers(query: QueryParams) {
  const params = Object.fromEntries(Object.entries(query).filter(([_, v]) => v != null))
  const queryParams = new URLSearchParams(params).toString()
  let url = `${customersRoute}/v2?${queryParams}`
  return await apiFetch(url, 'GET')
}

export async function fetchCustomer(id: string) {
  let url = `${customersRoute}/v2/${id}`
  return await apiFetch(url, 'GET')
}

export async function fetchConversations(email: string, phone: string) {
  const url = `/api/conversations?email=${email}&phone=${phone}`
  return await apiFetch(url, 'GET')
}

export async function fetchCSATAggregateByIds(query: string) {
  const queryParams = new URLSearchParams(query).toString()
  const url = `/api/conversations/csat-aggregate?${queryParams}`
  return await apiFetch(url, 'GET')
}

export async function fetchTransactionalEmails(customerId: string) {
  const url = `/api/transactional-emails/${customerId}`
  const emails = await apiFetch(url, 'GET')

  return emails.filter((item: any, index: string) => {
    return emails.findIndex((i: any) => i.message_id === item.message_id) === index
  })
}

export async function fetchCustomerRevenueAndOrdersTotalFromDWHAPi(email: string, shop: string) {
  const url = `${customersRoute}/dwh?email=${email}&shop=${shop}`
  return await apiFetch(url, 'GET')
}

export async function fetchNPSByCustomer(customerId: string) {
  const url = `/api/nps?customerId=${customerId}`
  return await apiFetch(url, 'GET')
}

// not in use yet
export async function fetchComplaintsByCustomer(customerId: string) {
  const url = `/api/complaints?customerId=${customerId}`
  return await apiFetch(url, 'GET')
}


export async function fetchMessagesForCsid(csid: string) {
  const url = `/api/conversations/${csid}/messages`
  return await apiFetch(url, 'GET')
}

export async function getChartData(customerId: number) {
  const url = `${customersRoute}/${customerId}/chart`
  return await apiFetch(url, 'GET')
}

export async function getAnnualPrintSpentData(customerId: number) {
  const url = `${customersRoute}/annual-print-spent/${customerId}`
  return await apiFetch(url, 'GET')
}

export async function getBalanceData(customerId: number) {
  const url = `${customersRoute}/${customerId}/balance`
  return await apiFetch(url, 'GET')
}

export async function updateCustomerAddress(customerId: number, addressId: number, body: Record<string, any>) {
  const url = `${customersRoute}/${customerId}/addresses/${addressId}`
  return await apiFetch(url, 'PATCH', body)
}

export async function createCustomerAddress(customerId: number, body: Record<string, any>) {
  const url = `${customersRoute}/${customerId}/addresses`
  return await apiFetch(url, 'POST', body)
}

export async function setDefaultDeliveryAddress(customerId: number, addressId: number) {
  const url = `${customersRoute}/${customerId}/addresses/default-delivery-address/${addressId}`
  return await apiFetch(url, 'PATCH')
}

export async function setDefaultInvoiceAddress(customerId: number, addressId: number) {
  const url = `${customersRoute}/${customerId}/addresses/default-invoice-address/${addressId}`
  return await apiFetch(url, 'PATCH')
}

export async function setEmailPreferences(payload: object, enabled: boolean) {
  const customerId = payload['customerId']
  const email = payload['email']
  const url = `${customersRoute}/${customerId}/email-preferences`
  return await apiFetch(url, 'PUT', { 
    enabled: enabled,
    email: email
  })
}

export async function deleteCustomerAddress(addressId: number) {
  const url = `${customersRoute}/addresses/${addressId}`
  return await apiFetch(url, 'DELETE')
}