<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    v-html="getIcon()">
  </svg>
</template>
<script setup>
// import icons here list available icons at https://icon-sets.iconify.design/solar/
import Home from '@iconify-icons/solar/home-smile-angle-linear'
import ArrowToDownRight from '@iconify-icons/solar/arrow-to-down-right-bold'
import ArrowToTopLeft from '@iconify-icons/solar/arrow-to-top-left-bold'
import ArrowLeft from '@iconify-icons/solar/arrow-left-bold'
import SolarShop2Linear from '@iconify-icons/solar/shop-2-linear'
import SolarCard2Linear from '@iconify-icons/solar/card-2-linear'
import SolarUserCircleLinear from '@iconify-icons/solar/user-circle-linear'
import SolarLetterLinear from '@iconify-icons/solar/letter-linear'
import SolarLetterOpenedLinear from '@iconify-icons/solar/letter-opened-linear'
import SolarPhoneLinear from '@iconify-icons/solar/phone-linear'
import SolarMapPointLinear from '@iconify-icons/solar/map-point-linear'
import SolarInboxInLinear from '@iconify-icons/solar/inbox-in-linear'
import SolarSidebarMinimalisticLinear from '@iconify-icons/solar/sidebar-minimalistic-linear'
import SolarFilterLinear from '@iconify-icons/solar/filter-linear'
import SolarDownloadSquareLinear from '@iconify-icons/solar/download-square-linear'
import SolarMagniferLinear from '@iconify-icons/solar/magnifer-linear'
import SolarTrashBin2Linear from '@iconify-icons/solar/trash-bin-2-linear'
import SolarCloseCircleLinear from '@iconify-icons/solar/close-circle-linear'
import SolarRefreshSquareLinear from '@iconify-icons/solar/refresh-square-linear'
import SolarShieldStarLinear from '@iconify-icons/solar/shield-star-linear'
import SolarChatRoundLineLinear from '@iconify-icons/solar/chat-round-line-linear'
import SolarAltArrowLeftLinear from '@iconify-icons/solar/alt-arrow-left-linear'
import SolarAltArrowRightLinear from '@iconify-icons/solar/alt-arrow-right-linear'
import SolarAltArrowDownBold from '@iconify-icons/solar/alt-arrow-down-bold'
import SolarSquareArrowRightUpLinear from '@iconify-icons/solar/square-arrow-right-up-linear'
import SolarCheckCircleLinear from '@iconify-icons/solar/check-circle-linear'
import SolarCartLargeMinimalisticLinear from '@iconify-icons/solar/cart-large-minimalistic-linear'
import SolarRulerLinear from '@iconify-icons/solar/ruler-linear'
import SolarSignpostLinear from '@iconify-icons/solar/signpost-linear'
import SolarCopyLinear from '@iconify-icons/solar/copy-linear'
import SolarBuildingsLinear from '@iconify-icons/solar/buildings-linear'
import SolarWalletMoneyLinear from '@iconify-icons/solar/wallet-money-linear'
import SolarInfoCircleLinear from '@iconify-icons/solar/info-circle-linear'
import SolarNotesLinear from '@iconify-icons/solar/notes-linear'
import SolarCardLinear from '@iconify-icons/solar/card-linear'
import SolarHandMoneyLinear from '@iconify-icons/solar/hand-money-linear'
import SolarBillListLinear from '@iconify-icons/solar/bill-list-linear'
import SolarBoxLinear from '@iconify-icons/solar/box-linear'
import SolarBag4Linear from '@iconify-icons/solar/bag-4-linear'
import SolarEuroLinear from '@iconify-icons/solar/euro-linear'
import SolarPenNewSquareOutline from '@iconify-icons/solar/pen-new-square-outline'
import SolarAddCircleLinear from '@iconify-icons/solar/add-circle-linear'
import SolarDocumentTextOutline from '@iconify-icons/solar/document-text-outline'
import SolarStreetsMapPointLinear from '@iconify-icons/solar/streets-map-point-linear'

const props = defineProps({
  name: String,
  width: {
    type: String,
    default: "1rem"
  },
  height: {
    type: String,
    default: "1rem"
  }
})

const getIcon = () => {
  switch (props.name) {
    case 'home':
      return Home.body
    case 'arrow-to-left':
      return ArrowLeft.body
    case 'arrow-to-down-right':
      return ArrowToDownRight.body
    case 'arrow-to-top-left':
      return ArrowToTopLeft.body
    case 'shop':
      return SolarShop2Linear.body
    case 'card':
      return SolarCard2Linear.body
    case 'user':
      return SolarUserCircleLinear.body
    case 'letter':
      return SolarLetterLinear.body
    case 'open-letter':
      return SolarLetterOpenedLinear.body
    case 'phone':
      return SolarPhoneLinear.body
    case 'location':
      return SolarMapPointLinear.body
    case 'inbox':
      return SolarInboxInLinear.body
    case 'sidebar':
      return SolarSidebarMinimalisticLinear.body
    case 'filter':
      return SolarFilterLinear.body
    case 'download':
      return SolarDownloadSquareLinear.body
    case 'search':
      return SolarMagniferLinear.body
    case 'trash':
      return SolarTrashBin2Linear.body
    case 'close':
      return SolarCloseCircleLinear.body
    case 'refresh':
      return SolarRefreshSquareLinear.body
    case 'shield':
      return SolarShieldStarLinear.body
    case 'chat':
      return SolarChatRoundLineLinear.body
    case 'arrow-left':
      return SolarAltArrowLeftLinear.body
    case 'arrow-right':
      return SolarAltArrowRightLinear.body
    case 'arrow-out':
      return SolarSquareArrowRightUpLinear.body
    case 'arrow-down':
      return SolarAltArrowDownBold.body
    case 'check':
      return SolarCheckCircleLinear.body
    case 'cart':
      return SolarCartLargeMinimalisticLinear.body
    case 'ruler':
      return SolarRulerLinear.body
    case 'branch':
      return SolarSignpostLinear.body
    case 'copy':
      return SolarCopyLinear.body
    case 'building':
      return SolarBuildingsLinear.body
    case 'wallet':
      return SolarWalletMoneyLinear.body
    case 'info':
      return SolarInfoCircleLinear.body
    case 'notes':
      return SolarNotesLinear.body
    case 'credit-card':
      return SolarCardLinear.body
    case 'refund':
      return SolarHandMoneyLinear.body
    case 'bill':
      return SolarBillListLinear.body
    case 'box':
      return SolarBoxLinear.body
    case 'bag':
      return SolarBag4Linear.body
    case 'euro':
      return SolarEuroLinear.body
    case 'edit':
      return SolarPenNewSquareOutline.body
    case 'add':
      return SolarAddCircleLinear.body
    case 'document':
      return SolarDocumentTextOutline.body
    case 'map':
      return SolarStreetsMapPointLinear.body
    default:
      return Home.body
  }
}
</script>