import { getOrders } from '@/services/apiOrders'
import { getShopDetails } from '@/services/apiShops'

import {
  fetchCustomers,
  fetchCustomer,
  fetchConversations,
  fetchCSATAggregateByIds,
  fetchTransactionalEmails,
  fetchCustomerRevenueAndOrdersTotalFromDWHAPi,
  fetchNPSByCustomer,
  fetchMessagesForCsid
} from '@/services/apiCustomers'

import {
  handlePriceColumnFilter,
  handleDefaultColumnFilter,
  handleDateColumFilter,
} from '@/helpers/format';

const state = () => ({
  customers: [],
  activeCustomer: null,
  customerOrders: [],
  customerConversations: [],
  allowedCountries: [],
  activeMessage: [],
  emails: [],
});

const getters = {
  customers: (state) => state.customers,
};


const actions = {
  async getCustomers({ commit }, req) {
    const params = req?.params ? getParams(req.params) : {};
    const response = await fetchCustomers(params);
    response.data = response.data.map((customer) => {
      return {
        id: customer.id_customer,
        ...customer,
        shopName: customer.shop.name.toLowerCase()
      }
    });
    
    commit('SET_CUSTOMERS', response);
    return response;
  },

  async getCustomer({ commit }, id) {
      const activeCustomer = await fetchCustomer(id);
      commit('SET_ACTIVE_CUSTOMER', activeCustomer.data);
      return activeCustomer.data;
  },

  async refreshCustomer({ commit }, id) {
    const response = await fetchCustomer(id);
    commit('SET_ACTIVE_CUSTOMER', response.data);
    return response.data;
  },

  async getCustomerOrders({ commit }, params) {
    const payload = params;
    const response = await getOrders(payload);
    commit('SET_CUSTOMER_ORDERS', response);
    return response;
  },

  async getCustomerConversations({ commit }, req) {
    const response = await fetchConversations(req.email, req.phone);
    commit('SET_CUSTOMER_CONVERSATIONS', response);
    return response;
  },

  async getMessagesById({ commit, state, rootGetters }, id) {
    const user = rootGetters['user'];
    const response = await fetchMessagesForCsid(id, user.idToken);
    commit('SET_ACTIVE_MESSAGE', response);
    return response;
  },

  async getTransactionalEmails({ commit, state, rootGetters }, req) {
    let response = await fetchTransactionalEmails(req);
    response = response.sort((a, b) => {
      return new Date(b.sent_at) - new Date(a.sent_at);
    });
    commit('SET_EMAILS', response);
    return response;
  },

  async getNpsScore({}, customer_id) {
    const response = await fetchNPSByCustomer(customer_id);
    return response;
  },

  async getCsatScores({ commit, rootGetters }, req) {
    const response = await fetchCSATAggregateByIds(req);
    return response;
  },

  async getFinancialData({ }, req) {
    const response = await fetchCustomerRevenueAndOrdersTotalFromDWHAPi(req.email, req.shop);
    return response;
  },

  async getCustomerAllowedCountries({ commit, state }, id) {
    if (id === null) {
      return null;
    }
    if(state.allowedCountries.customer_id === id) {
      return state.allowedCountries;
    }
    const response = await getShopDetails(id);
    commit('SET_CUSTOMER_SHOP', { customer_id: id, ...response });
    return response;
  }
};

// mutations
const mutations = {
  SET_CUSTOMERS(state, customers) {
    state.customers = customers;
  },
  SET_ACTIVE_CUSTOMER(state, customer) {
    state.activeCustomer = customer;
  },
  SET_CUSTOMER_ORDERS(state, orders) {
    state.customerOrders = orders;
  },
  SET_CUSTOMER_CONVERSATIONS(state, conversations) {
    state.customerConversations = conversations;
  },
  SET_ACTIVE_MESSAGE(state, message) {
    state.activeMessage = message;
  },
  SET_EMAILS(state, emails) {
    state.emails = emails;
  },
  SET_CUSTOMER_SHOP(state, shop) {
    state.allowedCountries = shop;
  }
};

// function getCustomerCity(customer) {
//   if( customer.default_delivery_address && customer.default_delivery_address.city ) {
//     return customer.default_delivery_address.city;
//   } 
//   return customer.address[0] ? customer.address[0].city : '';
// }

function getParams(params) {
  const perPage = params.request.endRow - params.request.startRow
  const page = params.request.startRow / perPage + 1

  let searchColumns = [];
  let searchTerms = [];
  if (params.request.filterModel) {
    searchColumns = Object.keys(params.request.filterModel);
    searchTerms = searchColumns.map(column => {
      const filterModel = params.request.filterModel[column];
      switch (column) {
        case 'date_add':
        case 'date_upd':
          return handleDateColumFilter(filterModel);
        case 'totalPrice':
          return handlePriceColumnFilter(filterModel);
        default:
          return handleDefaultColumnFilter(filterModel);
      }
    })
  }
  return {
    perPage,
    page,
    sortColumn: params.request.sortModel[0]?.colId || null,
    sortDirection: params.request.sortModel[0]?.sort || 'desc',
    'searchColumn[]': searchColumns.map(column => column),
    'searchTerm[]': searchTerms.map(term => encodeURIComponent(term)) || null,
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
