<template>
	<div class="app-layout">
    <el-container>
      
			<SideBar />
      <el-container direction="vertical">
        <!-- <Header /> -->
        <NavToggler />
        
				<RouterView />
				<AlertBar />
        
      </el-container>
    </el-container>
  </div>

</template>

<script setup>
import { provide, ref, watch} from 'vue'
import { RouterView } from 'vue-router'
import SideBar from './components/navigation/SideBar.vue'
import NavToggler from '@/components/navigation/NavToggler.vue'
import { useRoute } from "vue-router";

import AlertBar from './components/common/AlertBar.vue'

const collapsed = ref(false)
provide('collapsed', collapsed)

const route = useRoute();

watch(() => route.fullPath,
  async () => {
    if(route.fullPath !== '/') {
      collapsed.value = true
    };
  }
);

</script>

<style lang="scss">
.el-main {
  height: 100vh;
  padding: 40px;
  overflow: auto;
  background-color: var(--bg-color);
  transition: all 0.3s;
  position: relative;
}

</style>
