import { getOrders, getOrderStates, getPrintTypes } from '@/services/apiOrders';

const state = () => ({
  orders: [],
  allOrderStates: [],
  orderStates: [],
  orderDetailStates: [],
  printTypes: []
});

const getters = {
  orders: (state) => state.orders,
  allOrderStates: (state) => state.allOrderStates
};


const actions = {
  async fetchOrders({ commit }, params) {
    const response = await getOrders(params);
    commit('SET_ORDERS', response.data);
    return response.data;
  },
  async fetchAllOrderStates({ commit }) {
    const response = await getOrderStates({ page: 1, perPage: 1000 });
    commit('SET_ALL_ORDER_STATES', response.data);
    return response.data;
  },
  async getFilteredOrderStates({ commit, rootGetters }) {
    const allStates = rootGetters['orders/allOrderStates'];
    const filteredStates = allStates.filter((state) => state.order_state_type == 0);
    const orderStatesByName = groupStatesByIds(filteredStates);
    commit("SET_ORDER_STATES", orderStatesByName);
  },
  async getFilteredOrderDetailStates({ commit, rootGetters }) {
    const allStates = rootGetters['orders/allOrderStates'];
    const filteredStates = allStates.filter((state) => state.order_state_type == 1);
    const orderStatesByName = groupStatesByIds(filteredStates);
    commit("SET_ORDER_DETAIL_STATES", orderStatesByName);
  },
  async fetchPrintTypes({ commit }) {
    const response = await getPrintTypes();
    const parsed = response.data.map((item) => {
      return {
        id_order_detail_print_type: item.id_order_detail_print_type,
        name: item?.lang[0]?.pivot?.name || '',
      }
    })
    commit('SET_PRINT_TYPES', parsed);
    return parsed;
  },
  async resetOrders({ commit }) {
    commit('SET_ORDERS', []);
  }

};

// mutations
const mutations = {
  SET_ORDERS(state, orders) {
    state.orders = orders;
  },
  SET_ALL_ORDER_STATES(state, orderStates) {
    state.allOrderStates = orderStates;
  },
  SET_ORDER_STATES(state, orderStates) {
    state.orderStates = orderStates;
  },
  SET_ORDER_DETAIL_STATES(state, orderStates) {
    state.orderDetailStates = orderStates;
  },
  SET_PRINT_TYPES(state, printTypes) {
    state.printTypes = printTypes;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

const groupStatesByIds = (states) => {
  const orderStates = states.reduce((acc, item) => {
    acc[item.id_order_state] = item.order_state_code?.replace(/_/g, ' ');
    return acc;
  }, {});
  if (!Object.keys(orderStates).length > 0) { return }

  let orderStatesByName = {}
  Object.keys(orderStates).forEach((key) => {
    if (!!orderStates[key]) {
      if (orderStatesByName[orderStates[key]]) {
        orderStatesByName[orderStates[key]].push(key)
      } else {
        orderStatesByName[orderStates[key]] = [key]
      }
    }

  })
  orderStatesByName = Object.keys(orderStatesByName).map((key, index) => {
    return {
      id: index,
      value: orderStatesByName[key],
      name: key.replace(/_/g, ' ').toLowerCase() || '',
    }
  });
  return orderStatesByName.sort((a, b) => a.name.localeCompare(b.name));
}