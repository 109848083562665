import { getComplaints } from '@/services/apiComplaints';

const state = () => ({
  complaints: [],
});

const getters = {
  complaints: (state) => state.complaints,
};


const actions = {
  async fetchComplaints({ commit }, params) {

    const response = await getComplaints(params);
    const sortedComplaints = response.data.sort((a, b) => {
      return new Date(b.date_add) - new Date(a.date_add);
    })
    commit('SET_COMPLAINTS', sortedComplaints);
    return sortedComplaints;
  },
};

// mutations
const mutations = {
  SET_COMPLAINTS(state, complaints) {
    state.complaints = complaints;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
