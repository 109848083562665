import { apiFetch, apiFetchImgBlob } from "./apiFetch";

type QueryParams = {
  page?: string;
  perPage?: string;
  sortColumn?: string;
  sortDirection?: string;
  'searchColumn[]'?: string;
  'searchTerm[]'?: string;
  id_customer?: string;
  id_order?: string;
  id_order_detail?: string;
}

export async function getComplaints(query: QueryParams) {
  const queryParams = new URLSearchParams(query).toString()
  const url = `/api/complaints/order-detail-complaints?${queryParams}`
  return await apiFetch(url, 'GET')
}

export async function getComplaintsByCustomer(query: QueryParams) {
  const queryParams = new URLSearchParams(query).toString()
  const url = `/api/complaints?${queryParams}`
  return await apiFetch(url, 'GET')
}

export async function getComplaintsDepartments() {
  const url = `/api/complaints/complaints-departments`
  return await apiFetch(url, 'GET')
}

export async function getComplaintsDepartmentTypes(departmentId) {
  const url = `/api/complaints/complaints-departments-types?id=${departmentId}`
  return await apiFetch(url, 'GET')
}

export async function getComplaintAttachments(complaintId: string) {
  const url = `/api/complaints/order-detail-complaints/${complaintId}/attachments`
  return await apiFetch(url, 'GET')
}

export async function getComplaintsSolutions() {
  const url = `/api/complaints/complaints-solutions`
  return await apiFetch(url, 'GET')
}

export async function generateThumbail(body: object) {
  const url = `/api/complaints/generate-thumbnail`
  return await apiFetchImgBlob(url, 'POST', body)
}

export async function uploadComplaintAttachments(orderDetailId: string, complaintId: string, body: any) {
  const url = `/api/complaints/order-details/${orderDetailId}/complaints/${complaintId}/attach`
  return await apiFetch(url, 'POST', body)
}

export async function patchComplaintAttachments(orderDetailId: string, complaintId: string) {
  const url = `/api/complaints/order-details/${orderDetailId}/complaints/${complaintId}/attach`
  return await apiFetch(url, 'PATCH')
}

export async function createComplaint(orderDetailId: string, body: any) {
  const url = `/api/complaints/order-details/${orderDetailId}/complaints`
  return await apiFetch(url, 'POST', body)
}

export async function updateComplaint(orderDetailId: string, complaintId: string, body: any) {
  const url = `/api/complaints/order-details/${orderDetailId}/complaints/${complaintId}`
  return await apiFetch(url, 'PATCH', body)
}
