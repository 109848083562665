<template>
  <div class="sidebar__wrapper" :class="collapsed ? 'collapsed' : ''">
    <el-aside class="sidebar" :width="collapsed ? '80px' : '228px'">
      
      <div class="sidebar__top">
        <RouterLink v-if="collapsed" to="/" class="small-logo">
          <img :src="smallLogo" alt="logo" />
        </RouterLink>
        <RouterLink v-else  to="/" class="logo">
          <img :src="logo" alt="logo" />
        </RouterLink>
      </div>

      <el-menu
          :collapse="collapsed"
          :default-active="current"
          :unique-opened="true"
          class="sidebar__menu"
          :router="true"
        >
					<el-menu-item index="/orders">
            <el-icon><Icon name="cart" /></el-icon>
            <span>Orders</span>
          </el-menu-item>

          <el-menu-item index="/customers">
            <el-icon><User /></el-icon>
            <span>Customers</span>
          </el-menu-item>

					<el-menu-item index="/quotes">
            <el-icon><Icon name="ruler" /></el-icon>
            <span>Bespoke</span>
          </el-menu-item>

					<el-menu-item index="/decision-tree">
            <el-icon><Icon name="branch" /></el-icon>
            <span>Decision Tree</span>
          </el-menu-item>

          <!-- <el-sub-menu index="2">
            <template #title>
              <el-icon><ShoppingCartFull /></el-icon>
              <span>Orders</span>
            </template>
            <el-menu-item index="/orders">Search Order</el-menu-item>
            <el-menu-item index="/quotes">Bespoke Orders</el-menu-item>
            <el-menu-item index="/quotes/newquote">New Bespoke</el-menu-item>
          </el-sub-menu>

          <el-sub-menu index="/cx">
            <template #title>
              <el-icon><ChatDotRound /></el-icon>
              <span>CX</span>
            </template>
            <el-menu-item index="/decision-tree">
              <span>Decision Tree</span>
            </el-menu-item>
            <el-menu-item index="/helpcenter">Helpcenter</el-menu-item>
          </el-sub-menu> -->

          

          <!-- <el-menu-item index="/traffic">
            <el-icon><Van /></el-icon>
            <span>Traffic</span>
          </el-menu-item> -->
        </el-menu>
    </el-aside>
  </div>
</template>

<script setup>
import logo from '@/assets/logo-360-black.svg'
import smallLogo from '@/assets/logo-h.svg'
import { ref, onMounted, computed, inject } from 'vue'
import { useRoute } from 'vue-router';
import Icon from '@/components/common/Icon.vue';


const route = useRoute()
const current = computed(() => route.path)

import { User } from '@element-plus/icons-vue'

const collapsed = inject('collapsed')


</script>

<style scoped lang="scss">
	.sidebar {
		--el-menu-icon-width: 24px;
		--el-menu-base-level-padding: 18px;

		background-color: var(--sidebar-bg-color);
		position: fixed;
		height: 100%;
		z-index: 101;
		transition: all 0.4s;

		&__menu {
			padding-top: 32px;
		}

		&__wrapper {
			width: 228px;
			transition: all 0.4s;
			flex: none;
			&.collapsed {
				width: 80px;
			}
		}

		&__top {
			height: 40px;
			padding: 20px 0 0;
		}

		.logo {
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			img {
				width: 136px;
			}
		}

		.small-logo {
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			img {
				width: 20px;
			}
		}

		.el-menu {
			--el-menu-bg-color: var(--sidebar-bg-color);
			--el-menu-hover-bg-color: #f2f2f2;
			--el-menu-sub-item-height: 40px;
			--el-menu-level-padding: 30px;
			padding: 10px 20px;
			margin-top: 20px;
			overflow: hidden;
			transition: all 0.4s;
			border-right: none;
			height: calc(100% - 74px);

			.el-menu-item {
				--el-menu-sub-item-height: 44px;
				border-radius: var(--border-radius);
				margin-bottom: 10px;
				
				&.is-active {
					background-color: #f2f2f2;
				
					color: var(--primary-color);
				}
				&:hover {
					border-radius: var(--border-radius);
				}
			}

			.el-menu--inline {
				border-top: 1px solid #f1f1f1;
				border-bottom: 1px solid #f1f1f1;
				margin-top: 0;
				padding: 10px 0 0;
			}

			.el-sub-menu {
				border-radius: var(--border-radius);
				overflow: hidden;
				.el-menu-item {
					font-size: 13px;
				}
			}

			&--collapse {
				padding: 10px;
				width: 100%;
			}
		}
	}
</style>