/// <reference types="vite/client" />

const apiBaseUrl = import.meta.env.VITE_APP_API_BASE_URL

type Method = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH'
type Payload = {
  method: Method,
  headers: any,
  body?: any,
  responseType?: 'arraybuffer'
}

import { getAuth } from "firebase/auth"
import store from '../store'


export const apiFetch = async (url: RequestInfo, method: Method, body?: any, json = true) => {
  const idToken = await getUserToken()
  const headers = {
    'Authorization': `Bearer ${idToken}`
  }
  if (json) {
    headers['Content-Type'] = 'application/json'
  } else {
    headers['Content-Type'] = 'application/x-www-form-urlencoded'
  }

  try {
    const payload: Payload = { method, headers }
    if (method != 'GET') {
      payload.body = !!json ? JSON.stringify(body) : body
    } 
    const response = await fetch(
      apiBaseUrl + url,
      payload
    ).then(async (resp) => {
        if(resp.status === 403) {
          // redirect to login
          window.location.href = '/login'
          return {}
        }
        if (!resp.ok) {
          // send a alert to the user
          const error = await resp.json()
          store._actions['alerts/addAlert'][0]({
            type: 'error',
            message: error.message
          })
          throw new Error(error.message)
        }
        return await resp.json()
      })

    return response
  } catch (error) {
    console.error('Error fetching data:', error)
    throw error
  }
}

export const apiFetchBlob = async (url: RequestInfo, method: Method, body?: any) => {
  const idToken = await getUserToken()
  const headers = {
    'Authorization': `Bearer ${idToken}`,
    'Accept': 'application/pdf',
  }
  const apiUrl = apiBaseUrl + url

  try {
    const payload: Payload = {
      method,
      headers,
    }
    if (method !== 'GET') {
      payload.body = body
    }

    // Log raw response size first
    const response = await fetch(apiUrl,  payload)

    const blob = await response.blob()
    return blob
  } catch (error) {
    console.error('Error:', error)
    throw error
  }
}

export const apiFetchImgBlob = async (url: RequestInfo, method: Method, body?: any) => {
  const idToken = await getUserToken()
  const headers = {
    'Authorization': `Bearer ${idToken}`,
    'Content-Type': 'application/json'
  }
  const apiUrl = apiBaseUrl + url

  try {
    const payload: Payload = {
      method,
      headers,
    }
    if (method !== 'GET') {
      payload.body = JSON.stringify(body)
    }
    // Log raw response size first
    const response = await fetch(apiUrl,  payload)

    const blob = await response.blob()
    return blob
  } catch (error) {
    console.error('Error:', error)
    throw error
  }
}

const getUserToken = async () => {
  const user = getAuth().currentUser
  let idToken = ''
  if(user) {
    idToken = await user.getIdToken()
  }
  return idToken
}