import { apiFetch, apiFetchBlob } from "./apiFetch";

type QueryParams = {
  page?: string;
  perPage?: string;
  sortColumn?: string;
  sortDirection?: string;
  'searchColumn[]'?: string;
  'searchTerm[]'?: string;
  id_shop?: string;
  active?: string;
}

const ordersRoute = '/api/orders'
const standardPlusRoute = '/api/standard-plus'

export async function getQuotes(query: QueryParams) {
  const queryParams = new URLSearchParams(query).toString()
  const url = `${ordersRoute}/quotes?${decodeURIComponent(queryParams) as string}`
  return await apiFetch(url, 'GET')
}

export async function getOrders(query: QueryParams) {
  const queryParams = new URLSearchParams(query).toString()
  const url = `${ordersRoute}?${queryParams}`
  return await apiFetch(url, 'GET')
}

export async function getOrderOwners() {
  return await apiFetch(ordersRoute + '/owners', 'GET')
}

export async function getOrderStates(query: QueryParams) {
  const queryParams = new URLSearchParams(query).toString()
  const url = `${ordersRoute}/states?${queryParams}`
  return await apiFetch(url, 'GET')
}

export async function downloadQuote(quoteId: number) {
  const url = `${ordersRoute}/${quoteId}/pdf`
  return apiFetchBlob(url, 'GET')
}

export async function submitNewQuote(body: Object) {
  const url = `${ordersRoute}/quotes`
  return apiFetch(url, 'POST', body)
}

export async function getPrintTypes() {
  const url = `${ordersRoute}/print-types`
  return await apiFetch(url, 'GET')
}

export async function getServiceLevels(query: QueryParams) {
  const queryParams = new URLSearchParams(query).toString()
  const url = `${ordersRoute}/service-levels?${queryParams}`
  return await apiFetch(url, 'GET')
}

export async function getStandardPlusCategories() {
  const url = `${standardPlusRoute}/categories`
  return await apiFetch(url, 'GET')
}

export async function getStandardPlusProducts(categoryId: number) {
  const url = `${standardPlusRoute}/categories/${categoryId}/products`
  return await apiFetch(url, 'GET')
}

export async function getStandardPlusAttributes(productId: number) {
  const url = `${standardPlusRoute}/products/${productId}/attributes`
  return await apiFetch(url, 'GET')
}

export async function getStandardPlusAttributeVariations(attributeId: number) {
  const url = `${standardPlusRoute}/attributes/${attributeId}/variations`
  return await apiFetch(url, 'GET')
}

export async function getStandardPlusVariationsByAttributeAndProduct(productId: number, attributeId: number) {
  const url = `${standardPlusRoute}/products/${productId}/attributes/${attributeId}/variations`
  return await apiFetch(url, 'GET')
}

export async function getOrderHistory(orderId: string) {
  const url = `${ordersRoute}/${orderId}/history`
  return await apiFetch(url, 'GET')
}

export async function getOrderHistoryCancelReason(typeId: string) {
  const url = `${ordersRoute}/complaint-reason/${typeId}`
  return await apiFetch(url, 'GET')
}

export async function getOrderPayments(orderId: string) {
  const url = `${ordersRoute}/${orderId}/payments`
  return await apiFetch(url, 'GET')
}

export async function getOrderRefunds(orderId: string) {
  const url = `${ordersRoute}/${orderId}/refunds`
  return await apiFetch(url, 'GET')
}

export async function getOrderDetail(orderlineId: string) {
  const url = `${ordersRoute}/order-detail/${orderlineId}`
  return await apiFetch(url, 'GET')
}

export async function getOrderDetailHistory(orderlineId: string) {
  const url = `${ordersRoute}/order-detail/${orderlineId}/history`
  return await apiFetch(url, 'GET')
}

export async function getAddressInfo(orderId: string) {
  const url = `${ordersRoute}/${orderId}/address-info`
  return await apiFetch(url, 'GET')
}

export async function setOrderDeliveryAddress(orderId: string, idAddress: string, type: string) {
  const url = `${ordersRoute}/${orderId}/address`
  return await apiFetch(url, 'PUT', { address_id: idAddress, type: type })
}

export async function updateOrderAddress(orderId: string, address: object) {
  const url = `${ordersRoute}/${orderId}/address/update`
  return await apiFetch(url, 'PUT', address)
}

export async function getNextOrderStates(orderId: string) {
  const url = `${ordersRoute}/${orderId}/next-states`
  return await apiFetch(url, 'GET')
}

export async function setNewOrderState(orderId: string, id_order_state: string) {
  const url = `${ordersRoute}/${orderId}/states`
  return await apiFetch(url, 'PATCH', { new_state_id: id_order_state })
}

export async function setNewOrderlineState(orderlineId: string, id_order_state: string) {
  const url = `${ordersRoute}/order-detail/${orderlineId}/states`
  return await apiFetch(url, 'PATCH', { new_state_id: id_order_state })
}

export async function setNewOrderlineProductDescription(orderlineId: string, body: object) {
  const url = `${ordersRoute}/order-detail/${orderlineId}/product`
  return await apiFetch(url, 'PATCH', body)
}

export async function setNewOrderlinePrice(orderlineId: string, body: object) {
  const url = `${ordersRoute}/order-detail/${orderlineId}/price`
  return await apiFetch(url, 'PATCH', body)
}

export async function setNewOrderlineTax(orderlineId: string, body: object) {
  const url = `${ordersRoute}/order-detail/${orderlineId}/tax`
  return await apiFetch(url, 'PATCH', body)
}

export async function createOrderInvoicePDF(orderId: string) {
  const url = `${ordersRoute}/${orderId}/invoices`
  return await apiFetchBlob(url, 'POST')
}

export async function getProductCombinations(shopId: string, combination: string) {
  const url = `/api/products/combinations?id_shop=${shopId}&combination=${combination}`
  return await apiFetch(url, 'GET')
}

export async function getExtraServices(shopId: string, customerId: string) {
  const url = `/api/products/extra_services?id_shop=${shopId}&id_customer=${customerId}`
  return await apiFetch(url, 'GET')
}

export async function createOrder(body: Object) {
  const url = `${ordersRoute}/orders`
  return await apiFetch(url, 'POST', body)
}

export async function getOrderCalculation(query: QueryParams) {
  const queryParams = new URLSearchParams(query).toString()
  const url = `${ordersRoute}/orders/calculate?${queryParams}`
  return await apiFetch(url, 'GET')
}

export async function addProductToOrder(orderId: string, body: Object) {
  const url = `${ordersRoute}/${orderId}/order-details`
  return await apiFetch(url, 'POST', body)
}

export async function duplicateOrderline(orderId: string, orderlineId: string) {
  const url = `${ordersRoute}/${orderId}/order-details/${orderlineId}/duplicate`
  return await apiFetch(url, 'POST')
}

export async function removeOrderline(orderId: string, orderlineId: string) {
  const url = `${ordersRoute}/${orderId}/order-details/${orderlineId}`
  return await apiFetch(url, 'DELETE')
}